import React from 'react'
import {Link} from 'gatsby'
import {PommeLink} from '../components/Elements/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Pomme Journal"
      keywords={[
        `Pomme`,
        `Literary Journal`,
        `Journal`,
        'Fiction',
        'Poetry',
        'Pomme Journal',
      ]}
    />
    <CoolHeader>Issue 01: Fernweh is Live!</CoolHeader>
    <p>
      We are so pleased with our first issue, <i>Fernweh</i>, which made its
      debut in November 2019. We hope that it inspires readers to embark on a
      journey to a faraway place, reminisce time long gone or simply dance
      boldly into the unknown. Be sure to check it out on Amazon, available in{' '}
      <Link to="/store">print or e-book.</Link>
    </p>
    <div
      style={{
        margin: '4rem auto',
        // maxWidth: `400px`,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: `1.45rem`,
      }}
    >
      <PommeLink to="/submit">Submit Your Work to Pomme Journal</PommeLink>
    </div>
  </Layout>
)

export default IndexPage
