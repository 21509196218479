import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const ExternalLink = styled.a`
  color: white;
  background: ${({ theme }) => theme.primary};
  border: 1px solid;
  border-radius: 4px;
  text-decoration: none;
  padding: 0.25rem;
  transition: all 200ms;
  &:hover {
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
  }
  &:focus {
    background: white;
    color: ${({ theme }) => theme.primary};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.primary};
    outline: none;
  }
`;

export const PommeLink = styled(Link)`
  color: ${({ theme }) => theme.secondary};
  border: 1px solid;
  border-radius: 4px;
  text-decoration: none;
  padding: 0.25rem 1rem;
  transition: all 200ms;
  &:hover {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.secondary};
  }
  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.secondary};
    outline: none;
  }
`;

export default PommeLink;
